<template>
  <div class="order-details-container">
    <!-- 待支付头部 -->
    <div
      v-if="waitForPay"
      class="order-details-wait-for-pay col-center"
    >
      <div class="row-center">
        <img
          style="width: 23px; height: auto; margin-right: 8px; margin-top: 13px"
          src="../assets/icon/donation/wait-pay.png"
        />
        <p class="wait-for-pay-title">待支付金额</p>
      </div>
      <!-- 只有购买订单显示倒计时 -->
      <p class="order-detail-prise">
        ¥{{computedAmount }}
      </p>
      <van-count-down
        v-if="computedLefttime"
        class="time-limit-tip"
        :auto-start="autoStart"
        :time="lefttime"
        format="剩余 mm 分 ss 秒"
        @finish="timeFinish"
      />
      <p class="time-limit">{{ timeLimit }}</p>
      <div>
        <button
          @click="cancerOrder"
          class="cancer"
        >取消订单</button>
        <button
          v-if="!isPicoShowPay"
          @click="payNow()"
          class="pay-now"
        >
          立即支付
        </button>
      </div>
      <!-- 购买订单有超时取消逻辑 -->
      <p
        v-if="$route.query.type == 'buy' || $route.query.type == 'composite'"
        class="order-tip"
      >
        超时订单将自动取消
      </p>
    </div>
    <!-- 状态 -->
    <div
      v-if="finishStatus || cancerStatus || donationStatus || onChainStatus"
      class="order-details-type row-center"
    >
      <img :src="
          onChainStatus
            ? require('../assets/icon/on-chain.png')
            : finishStatus || donationStatus
            ? require('../assets/icon/order_success.png')
            : require('../assets/icon/order_error.png')
        " />
      {{
        onChainStatus
          ? "上链中"
          : finishStatus || donationStatus
          ? "完成"
          : "已取消"
      }}
    </div>
    <!-- 转赠和购买订单 -->
    <div v-if="$route.query.type != 'composite'">
      <div
        v-if="(finishStatus || cancerStatus || donationStatus || onChainStatus) && $route.query.type == 'buy'"
        style="padding: 22px 0.875rem 14px 0.875rem;"
        class="order-detail-img-name-price row-start"
      >
        <div
          class="order-detail-img-name-price-img"
          :style="'width: 80px;height: 80px;margin:18px 18px 18px 0px;background-color: transparent;border-radius: 4px;'"
        >
          <div class="order-detail-img-name-price-img-inline">
            <img
              style="border-radius: 4px"
              :src="
                $route.query.type == 'buy'
                  ? orderInfo.cartInfo[0].productInfo.image
                  : orderInfo.image
              "
              alt=""
            />
          </div>
        </div>
        <!-- <div :style="'width: 80px;height: 80px;margin: 18px 18px 18px 20px;background-image: url('+ ($route.query.type == 'buy' ? orderInfo.cartInfo[0].productInfo.image : orderInfo.image) +');background-repeat: no-repeat;background-attachment: scroll;background-position: 50% 50%;background-size: 160% auto;background-color: transparent;border-radius: 4px;'"></div> -->
        <div>
          <p class="order-detail-name">{{ orderInfo.cartInfo[0].productInfo.storeName }}</p>
          <p
            v-if="$route.query.type == 'buy'"
            class="order-detail-prise"
          >
            ¥ {{ orderInfo.cartInfo[0].productInfo.price }}
          </p>
          <!-- 需要添加藏品类型 -->
          <img
            v-if="$route.query.type == 'donation'"
            class="order-detail-type"
            :src="
              mediaType[orderInfo.skuInfo.pid] == 'img'
                ? require('../assets/icon/media/img.png')
                : mediaType[orderInfo.skuInfo.pid] == 'audio'
                ? require('../assets/icon/media/audio.png')
                : mediaType[orderInfo.skuInfo.pid] == 'video'
                ? require('../assets/icon/media/video.png')
                : require('../assets/icon/media/3d.png')
            "
            alt=""
          />
          <p
            v-if="$route.query.type == 'donation'"
            class="order-detail-id"
          >
            ID:{{ orderInfo.showId   && orderInfo.showId != '0' ? orderInfo.showId: orderInfo.uniqueCode }}
          </p>
        </div>
      </div>
      <div
        v-if="$route.query.type !== 'buy'"
        class="composite"
      >
        <div
          v-if="$route.query.type != 'buy'"
          class="title"
        >转赠藏品</div>
        <div class="list">
          <div
            class="item"
            v-for="(i, j) in donationCollectionShowList"
            :key="j"
          >
            <div class="icon">
              <span class="icon-container">
                <img
                  class="icon-container-img"
                  :src="i.image"
                />
              </span>
            </div>
            <div class="info">
              <div class="name">{{i.name}}</div>
              <div
                style="width: 60vw;word-break: break-all;"
                class="id"
              >ID：{{i.showId && i.showId != '0' ? i.showId : i.uniqueCode}}</div>
            </div>
          </div>
        </div>
        <div
          class="expand"
          @click="donationShowMore"
          v-if="donationCollectionListshow"
        >
          <p>展开</p>
          <img
            src="../assets/icon/composite/expand.png"
            alt=""
          >
        </div>
      </div>
      <div
        v-if="finishStatus || cancerStatus || donationStatus || onChainStatus"
        style="padding-top: 22px"
        class="order-detail-content"
      >
        <!-- 订单编号 -->
        <div class="row-between">
          <p class="order-detail-item-title">订单编号</p>
          <p class="order-detail-item-content">
            {{ orderInfo.orderId }}
            <a
              class="orderNo-copy"
              :data-clipboard-text="orderInfo.orderId"
              @click="copy"
            >复制</a>
          </p>
        </div>
        <!-- 下单时间 -->
        <div
          v-if="$route.query.type == 'buy'"
          class="row-between"
        >
          <p class="order-detail-item-title">下单时间</p>
          <p class="order-detail-item-content">{{ orderInfo.createTime }}</p>
        </div>
        <!-- 接收人账户 -->
        <div
          v-if="$route.query.type == 'donation'"
          class="row-between"
        >
          <p class="order-detail-item-title">
            {{ donationStatus ? "赠送人账户" : "接受人账户" }}
          </p>
          <p class="order-detail-item-content">
            {{ donationStatus ? orderInfo.senderId : orderInfo.receiverId }}
          </p>
        </div>
        <!-- 数量 -->
        <div class="row-between">
          <p class="order-detail-item-title">数量</p>
          <p class="order-detail-item-content">
            {{
              $route.query.type == "buy" ? orderInfo.totalNum : orderInfo.amount
            }}
          </p>
        </div>

        <!-- 上链服务费 -->
        <div
          v-if="$route.query.type == 'donation' && !donationStatus"
          class="row-between"
        >
          <p class="order-detail-item-title">上链服务费</p>
          <p class="order-detail-item-content">{{ orderInfo.payAmount }}</p>
        </div>
        <!-- 单价 -->
        <div
          v-if="$route.query.type == 'buy'"
          class="row-between"
        >
          <p class="order-detail-item-title">单价</p>
          <p class="order-detail-item-content">
            ¥{{ orderInfo.cartInfo[0].productInfo.price }}
          </p>
        </div>
        <!-- 积分 -->
        <div
          v-if="$route.query.type == 'buy' && orderInfo.deductionPrice"
          class="row-between"
        >
          <p class="order-detail-item-title">积分</p>
          <p class="order-detail-item-content">-¥{{ orderInfo.deductionPrice }}</p>
        </div>
        <!-- 优惠券 -->
        <div
          v-if="$route.query.type == 'buy' && orderInfo.couponPrice"
          class="row-between"
        >
          <p class="order-detail-item-title">优惠券</p>
          <p class="order-detail-item-content">-¥{{ orderInfo.couponPrice }}</p>
        </div>
        <!-- 合计金额 -->
        <div
          v-if="$route.query.type == 'buy'"
          class="row-between"
        >
          <p class="order-detail-item-title">合计金额</p>
          <p class="order-detail-item-content">¥{{ (parseInt((orderInfo.totalPrice * 100).toFixed(0))  - parseInt((orderInfo.deductionPrice * 100).toFixed(0))  - parseInt((orderInfo.couponPrice * 100).toFixed(0))) / 100 }}</p>
        </div>
        <!-- 支付方式 -->
        <div
          v-if="(finishStatus || onChainStatus) && !donationStatus"
          class="row-between"
        >
          <p class="order-detail-item-title">支付方式</p>
          <p class="order-detail-item-content row-center">
            <img
              class="order-detail-item-paySelect"
              :src="
                orderInfo.payType == 'weixin'
                  ? require('../assets/icon/wx_pay.png')
                  : require('../assets/icon/zfb.png')
              "
            />
            {{ orderInfo.payType == "weixin" ? "微信支付" : "支付宝支付" }}
          </p>
        </div>
        <!-- 转赠时间 -->
        <div
          v-if="$route.query.type == 'donation'"
          class="row-between"
        >
          <p class="order-detail-item-title">转赠时间</p>
          <p class="order-detail-item-content">{{ orderInfo.sendTime }}</p>
        </div>
        <!-- 付款时间 -->
        <div
          v-if="$route.query.type == 'buy' && finishStatus"
          class="row-between"
        >
          <p class="order-detail-item-title">付款时间</p>
          <p class="order-detail-item-content">
            {{ orderInfo.payTime | formatDate }}
          </p>
        </div>
        <!-- 付款时间 -->
        <div
          v-if="
            $route.query.type == 'donation' &&
            (finishStatus || onChainStatus) &&
            !donationStatus
          "
          class="row-between"
        >
          <p class="order-detail-item-title">付款时间</p>
          <p class="order-detail-item-content">{{ orderInfo.payTime }}</p>
        </div>
      </div>
      <div
        v-if="waitForPay"
        class="order-detail-content"
        :style="$route.query.type == 'donation' && waitForPay ? 'padding-top: 12px;' : ''"
      >
        <div
          v-if="$route.query.type == 'buy'"
          class="order-detail-img-name-price row-start"
          style="
            margin: 0 3% 26px 3%;
            width: 88vw;
            border-bottom: 1px solid #343434;
          "
        >
          <div
            class="order-detail-img-name-price-img"
            :style="'width: 80px;height: 80px;margin:18px 18px 18px 0px;background-color: transparent;border-radius: 4px;'"
          >
            <div class="order-detail-img-name-price-img-inline">
              <img
                style="border-radius: 4px"
                :src="
                  $route.query.type == 'buy'
                    ? orderInfo.cartInfo[0].productInfo.image
                    : orderInfo.image
                "
                alt=""
              />
            </div>
          </div>

          <!-- <div :style="'width: 80px;height: 80px;margin: 18px 18px 18px 0px;background-image: url('+ ($route.query.type == 'buy' ? orderInfo.cartInfo[0].productInfo.image : orderInfo.image) +');background-repeat: no-repeat;background-attachment: scroll;background-position: 50% 50%;background-size: 160% auto;background-color: transparent;border-radius: 4px;'"></div> -->
          <div>
            <p class="order-detail-name">
              {{
                $route.query.type == "buy"
                  ? orderInfo.cartInfo[0].productInfo.storeName
                  : orderInfo.name
              }}
            </p>
            <p
              v-if="$route.query.type == 'buy'"
              class="order-detail-prise"
            >
              ¥ {{ orderInfo.cartInfo[0].productInfo.price}}
            </p>
            <!-- 需要添加藏品类型 -->
            <img
              class="order-detail-type"
              :src="
                mediaType[orderInfo.cartInfo[0].productInfo.cateId] == 'img'
                  ? require('../assets/icon/media/img.png')
                  : mediaType[orderInfo.cartInfo[0].productInfo.cateId] == 'audio'
                  ? require('../assets/icon/media/audio.png')
                  : mediaType[orderInfo.cartInfo[0].productInfo.cateId] == 'video'
                  ? require('../assets/icon/media/video.png')
                  : require('../assets/icon/media/3d.png')
              "
              alt=""
            />
            <p
              v-if="$route.query.type == 'donation'"
              class="order-detail-id"
            >
              ID:{{ orderInfo.showId && orderInfo.showId != '0' ? orderInfo.showId : orderInfo.uniqueCode }}
            </p>
          </div>
        </div>
        <!-- 订单编号 -->
        <div class="row-between">
          <p class="order-detail-item-title">订单编号</p>
          <p class="order-detail-item-content">
            {{ orderInfo.orderId }}
            <a
              class="orderNo-copy"
              :data-clipboard-text="orderInfo.orderId"
              @click="copy"
            >复制</a>
          </p>
        </div>
        <!-- 下单时间 -->
        <div
          v-if="$route.query.type == 'buy'"
          class="row-between"
        >
          <p class="order-detail-item-title">下单时间</p>
          <p class="order-detail-item-content">{{ orderInfo.createTime }}</p>
        </div>
        <!-- 接收人账户 -->
        <div
          v-if="$route.query.type == 'donation'"
          class="row-between"
        >
          <p class="order-detail-item-title">接受人账户</p>
          <p class="order-detail-item-content">{{ orderInfo.receiverId }}</p>
        </div>

        <!-- 数量 -->
        <div class="row-between">
          <p class="order-detail-item-title">数量</p>
          <p class="order-detail-item-content">
            {{
              $route.query.type == "buy" ? orderInfo.totalNum : orderInfo.amount
            }}
          </p>
        </div>

        <!-- 上链服务费 -->
        <div
          v-if="$route.query.type == 'donation'"
          class="row-between"
        >
          <p class="order-detail-item-title">上链服务费</p>
          <p class="order-detail-item-content">{{ orderInfo.payAmount }}</p>
        </div>

        <!-- 转赠时间 -->
        <div
          v-if="$route.query.type == 'donation'"
          class="row-between"
        >
          <p class="order-detail-item-title">转赠时间</p>
          <p class="order-detail-item-content">{{ orderInfo.sendTime }}</p>
        </div>

        <!-- 单价 -->
        <div
          v-if="$route.query.type == 'buy'"
          class="row-between"
        >
          <p class="order-detail-item-title">单价</p>
          <p class="order-detail-item-content">
            ¥{{ orderInfo.cartInfo[0].productInfo.price }}
          </p>
        </div>
        <!-- 积分 -->
        <div
          v-if="$route.query.type == 'buy' && orderInfo.deductionPrice"
          class="row-between"
        >
          <p class="order-detail-item-title">积分</p>
          <p class="order-detail-item-content">-¥{{ orderInfo.deductionPrice }}</p>
        </div>
        <!-- 优惠券 -->
        <div
          v-if="$route.query.type == 'buy' && orderInfo.couponPrice"
          class="row-between"
        >
          <p class="order-detail-item-title">优惠券</p>
          <p class="order-detail-item-content">-¥{{ orderInfo.couponPrice }}</p>
        </div>
        <!-- 合计金额 -->
        <div
          v-if="$route.query.type == 'buy'"
          class="row-between"
        >
          <p class="order-detail-item-title">合计金额</p>
          <p class="order-detail-item-content">¥{{ (parseInt((orderInfo.totalPrice * 100).toFixed(0)) - parseInt((orderInfo.deductionPrice * 100).toFixed(0))  - parseInt((orderInfo.couponPrice * 100).toFixed(0))) / 100}}</p>
        </div>
        <!-- 支付方式 -->
        <div
          v-if="finishStatus || onChainStatus"
          class="row-between"
        >
          <p class="order-detail-item-title">支付方式</p>
          <p class="order-detail-item-content row-center">
            <img
              class="order-detail-item-paySelect"
              :src="
                orderInfo.payType == 'weixin'
                  ? require('../assets/icon/wx_pay.png')
                  : require('../assets/icon/zfb.png')
              "
            />
            {{ orderInfo.payType == "weixin" ? "微信支付" : "支付宝支付" }}
          </p>
        </div>
        <!-- 付款时间 -->
        <div
          v-if="finishStatus && $route.query.type == 'buy'"
          class="row-between"
        >
          <p class="order-detail-item-title">付款时间</p>
          <p class="order-detail-item-content">
            {{ orderInfo.payTime | formatDate }}
          </p>
        </div>
      </div>
      <div
        v-if="waitForPay"
        class="order-detail-content"
        style="height: 3.125rem"
      >
        <div
          class="row-between"
          style="height: 100%"
        >
          <p style="
              font-size: 12px;
              font-family: lantingheiweight;
              font-weight: 400;
              color: #b3b3b3;
              line-height: 16px;
              margin-left: 0.875rem;
            ">
            支付方式
          </p>
          <p
            style="
              font-size: 12px;
              font-family: lantingheiweight;
              font-weight: bold;
              color: #b3b3b3;
              line-height: 16px;
              margin-right: 1.0625rem;
            "
            class="row-center"
          >
            <img
              class="order-detail-item-paySelect"
              :src="
                orderInfo.payType == 'weixin'
                  ? require('../assets/icon/wx_pay.png')
                  : require('../assets/icon/zfb.png')
              "
            />
            {{ orderInfo.payType == "weixin" ? "微信支付" : "支付宝支付" }}
          </p>
        </div>
      </div>
    </div>
    <!-- 合成订单 -->
    <div v-if="$route.query.type == 'composite'">
      <div
        v-if="finishStatus || cancerStatus || donationStatus || onChainStatus"
        style="padding: 22px 0.875rem 14px 0.875rem"
        class="order-detail-img-name-price row-start"
      >
        <div
          class="order-detail-img-name-price-img"
          :style="'width: 80px;height: 80px;margin:18px 18px 18px 0px;background-color: transparent;border-radius: 4px;'"
        >
          <div class="order-detail-img-name-price-img-inline">
            <img
              style="border-radius: 4px"
              :src="orderInfo.mergeTokenOrder.coverUrl"
              alt=""
            />
          </div>
        </div>
        <!-- <div :style="'width: 80px;height: 80px;margin: 18px 18px 18px 20px;background-image: url('+ ($route.query.type == 'buy' ? orderInfo.cartInfo[0].productInfo.image : orderInfo.image) +');background-repeat: no-repeat;background-attachment: scroll;background-position: 50% 50%;background-size: 160% auto;background-color: transparent;border-radius: 4px;'"></div> -->
        <div>
          <p class="order-detail-name">
            {{ orderInfo.mergeTokenOrder.displayName }}
          </p>
          <p class="order-detail-prise">
            {{ orderInfo.mergeTokenOrder.mergeNum }}个
          </p>
          <!-- 需要添加藏品类型 -->
          <!-- <img
            class="order-detail-type"
            :src="mediaType[orderInfo.skuInfo.pid] == 'img' ? require('../assets/icon/media/img.png') : mediaType[orderInfo.skuInfo.pid] == 'audio' ?  require('../assets/icon/media/audio.png'): mediaType[orderInfo.skuInfo.pid] == 'video' ?  require('../assets/icon/media/video.png') :  require('../assets/icon/media/3d.png')"
            alt=""
          > -->
          <!-- <p
            class="order-detail-id"
          >ID:{{ orderInfo.mergeTokenOrder.uniqueCodes }}</p> -->
        </div>
      </div>
      <div class="composite">
        <div class="title">合成材料</div>
        <div class="list">
          <div
            class="item"
            v-for="(i, j) in materialListShow"
            :key="j"
          >
            <div class="icon">
              <span class="icon-container">
                <img
                  class="icon-container-img"
                  :src="i.image"
                />
              </span>
            </div>
            <div class="info">
              <div class="name">{{ i.name }}</div>
              <div class="id">ID：{{ i.showId ? i.showId : i.uniqueCode }}</div>
            </div>
          </div>
        </div>
        <div
          class="expand"
          @click="showMore"
          v-if="
            materialListCache.length > 1 &&
            materialListCache.length != materialListShow.length
          "
        >
          <p>展开</p>
          <img
            src="../assets/icon/composite/expand.png"
            alt=""
          />
        </div>
      </div>
      <div
        style="padding-top: 22px"
        class="order-detail-content"
      >
        <!-- 订单编号 -->
        <div class="row-between">
          <p class="order-detail-item-title">订单编号</p>
          <p class="order-detail-item-content">
            {{ orderInfo.mergeTokenOrder.orderNo }}
            <a
              class="orderNo-copy"
              :data-clipboard-text="orderInfo.mergeTokenOrder.orderNo"
              @click="copy"
            >复制</a>
          </p>
        </div>
        <!-- 下单时间 -->

        <!-- 接收人账户 -->
        <div class="row-between">
          <!-- <p class="order-detail-item-title">{{ donationStatus ? '赠送人账户' : '接受人账户'}}</p>
          <p class="order-detail-item-content">{{  donationStatus ? orderInfo.senderId : orderInfo.receiverId}}</p> -->
        </div>
        <!-- 数量 -->
        <div class="row-between">
          <p class="order-detail-item-title">数量</p>
          <p class="order-detail-item-content">
            {{ orderInfo.mergeTokenOrder.mergeNum }}
          </p>
        </div>

        <!-- 上链服务费 -->
        <div class="row-between">
          <p class="order-detail-item-title">上链服务费</p>
          <p class="order-detail-item-content">
            {{ orderInfo.mergeTokenOrder.serviceFee }}
          </p>
        </div>
        <div class="row-between">
          <p class="order-detail-item-title">下单时间</p>
          <p class="order-detail-item-content">
            {{ orderInfo.mergeTokenOrder.submitTime | formatDate }}
          </p>
        </div>
        <div
          class="row-between"
          v-if="finishStatus && orderInfo.mergeTokenOrder.serviceFee != '0'"
        >
          <p class="order-detail-item-title">付款时间</p>
          <p class="order-detail-item-content">
            {{ orderInfo.mergeTokenOrder.payTime | formatDate }}
          </p>
        </div>
        <!-- 支付方式 -->
        <div
          v-if="
            (finishStatus || onChainStatus) &&
            !donationStatus &&
            orderInfo.mergeTokenOrder.serviceFee != '0'
          "
          class="row-between"
        >
          <p class="order-detail-item-title">支付方式</p>
          <p class="order-detail-item-content row-center">
            <img
              class="order-detail-item-paySelect"
              :src="
                orderInfo.mergeTokenOrder.payType == 'weixin'
                  ? require('../assets/icon/wx_pay.png')
                  : require('../assets/icon/zfb.png')
              "
            />
            {{
              orderInfo.mergeTokenOrder.payType == "weixin"
                ? "微信支付"
                : "支付宝支付"
            }}
          </p>
        </div>
      </div>
    </div>
    <van-popup
      v-model="cancerReasonVisible"
      closeable
      class="buy-good"
      position="bottom"
      style="height: 486px; background: rgba(21, 21, 21, 0.85)"
    >
      <div class="col-center">
        <p class="cancer-order-title">请选择取消订单的原因(必选)</p>
        <el-radio-group
          style="
            margin-top: 30px;
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          "
          v-model="cancerReason"
        >
          <el-radio label="不想要了">不想要了</el-radio>
          <el-radio label="商品错选/多选">商品错选/多选</el-radio>
          <el-radio label="商品没货了">商品没货了</el-radio>
          <el-radio label="没用/少用/错用优惠">没用/少用/错用优惠</el-radio>
          <el-radio label="其他">
            其他
            <div style="width: 100vw">
              <el-input
                v-model="cancerReasonText"
                type="textarea"
              />
            </div>
          </el-radio>
        </el-radio-group>
        <button
          @click="cancerOrderReason"
          class="cancer-submit"
        >提交</button>
      </div>

      <span
        slot="footer"
        class="dialog-footer"
      > </span>
    </van-popup>
    <el-dialog
      title="确定要取消订单吗？"
      :visible.sync="cancerVisible"
      @confirm="donationCancerOrder()"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top: 2rem"
        class="dialog-footer row-center"
      >
        <el-button @click="cancerVisible = false">取 消</el-button>
        <div
          @click="donationCancerOrder()"
          class="confirm-button row-center"
        >
          确定
        </div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="browerOpenVisible"
      @confirm="browerOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important; margin-left: 5%"
        class="donation-error-title"
      >
        请在微信以外浏览器内完成支付
      </p>
      <span
        slot="footer"
        style="margin-top: 2rem"
        class="dialog-footer row-center"
      >
        <div
          @click="browerOpenVisible = false"
          class="donation-button row-center"
        >
          确定
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "../api/index-client";
import Clipboard from "clipboard";
import Cookies from "js-cookie";
import Vue from "vue";
import config from "../config";
import { getAuthCode } from "../lib/wechat.js";
import { mapGetters } from "vuex";
import { CountDown,Popup,Button } from "vant";
import moment from "moment";
import { Dialog,Radio,Input,RadioGroup } from "element-ui";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(CountDown);
Vue.use(Button);
Vue.use(Popup);
export default {
  data () {
    return {
      donationCollectionShowList: [],
      donationCollectionList: [],
      donationCollectionListshow: false,
      browerOpenVisible: false,
      lefttime: 300000,
      cancerVisible: false,
      cancerReasonVisible: false,
      cancerReasonText: "",
      cancerReason: "",
      orderInfo: {
        cartId: "",
        skuInfo: {
          pid: "",
        },
        cartInfo: [
          {
            productInfo: {
              image: "",
            },
          },
        ],
        mergeTokenOrder: {},
      },
      finishStatus: false,
      onChainStatus: false,
      cancerStatus: false,
      donationStatus: false,
      waitForPay: false,
      timeLimit: null,
      autoStart: false,
      isPicoShowPay: false,
      payType: "weixin",
      mediaType: config.mediaType,
      materialListShow: [1],
      materialListCache: [1,2],
      materialHide: false,
    };
  },
  computed: {
    ...mapGetters(["userOpenId"]),
    // 待支付金额计算
    computedAmount () {
      return this.$route.query.type == "buy" && this.orderInfo.deductionPrice && this.orderInfo.couponPrice
        ? (parseInt((this.orderInfo.totalPrice * 100).toFixed(0)) - parseInt((this.orderInfo.deductionPrice * 100).toFixed(0)) - parseInt((this.orderInfo.couponPrice * 100).toFixed(0))) / 100
        : this.$route.query.type == "buy" && this.orderInfo.deductionPrice
          ? (parseInt((this.orderInfo.totalPrice * 100).toFixed(0)) - parseInt((this.orderInfo.deductionPrice * 100).toFixed(0))) / 100
          : this.$route.query.type == "buy" && this.orderInfo.couponPrice
            ? (parseInt((this.orderInfo.totalPrice * 100).toFixed(0)) - parseInt((this.orderInfo.couponPrice * 100).toFixed(0))) / 100
            : this.$route.query.type == "buy" && !this.orderInfo.deductionPrice && !this.orderInfo.couponPrice
              ? this.orderInfo.totalPrice
              : this.$route.query.type == "composite"
                ? this.orderInfo.mergeTokenOrder.serviceFee
                : this.orderInfo.payAmount
    },
    // 倒计时是否显示计算
    computedLefttime () {
      return this.lefttime > 0 && (this.$route.query.type == 'buy' || this.$route.query.type == 'composite')
    },
  },
  created () {
    this.isPicoShowPay = Cookies.get("Device") == "pico";
  },
  mounted () {
    if (this.$route.query.unique) {
      localStorage.setItem("orderWeixinPayUnique",this.$route.query.unique);
    }
    if (this.$route.query.orderId) {
      localStorage.setItem("orderWeixinPayOrderId",this.$route.query.orderId);
    }
    this.$store.commit("SHOW_APPLOADING");
    if (window.location.search.split("?code=").length == 2) {
      this.getOpenid(
        window.location.search.split("?code=")[1].split("&state=")[0]
      );
    } else {
      this.getOrderDetailsInfo();
    }
  },
  methods: {
    // 根据code获取openId
    getOpenid (code) {
      api.get("/wechat/getOpenid?code=" + code).then((result) => {

        if (result.data.success) {
          if (this.userOpenId != result.data.data.openId) {
            this.setOpenId(result.data.data.openId);
          } else {
            this.pay();
            // this.$store.commit('HIDE_APPLOADING')
          }
          this.$store.commit("SET_USEROPENID",result.data.data.openId);
        } else {
          this.$toast({
            message: result.data.msg,
            icon: require("../assets/icon/toast-error.png"),
          });
        }
      });
    },
    // 修改用户OpenId
    setOpenId (openId) {
      api.post("user/updateOpenid",{ openId: openId }).then((result) => {

        if (result.data.success) {
          // this.$store.commit('HIDE_APPLOADING')
          this.pay();
          this.$store.commit("SET_USEROPENID",result.data.data.openId);
        } else {
          this.$toast({
            message: result.data.msg,
            icon: require("../assets/icon/toast-error.png"),
          });
        }
      });
    },
    // 判断是否在微信中
    isWechat () {
      return (
        /micromessenger/i.test(navigator.userAgent) ||
        typeof WeixinJSBridge !== "undefined"
      );
    },
    // 倒计时显示
    showtime () {
      var nowtime = new Date(); //获取当前时间
      var endtime =
        this.$route.query.type == "buy"
          ? new Date(this.orderInfo.createTime.replace(/-/g,"/"))
          : this.$route.query.type == "composite"
            ? new Date(this.orderInfo.mergeTokenOrder.createTime)
            : new Date(this.orderInfo.sendTime.replace(/-/g,"/")); //定义结束时间
      this.lefttime = endtime.getTime() + 300000 - nowtime.getTime(); //距离结束时间的毫秒数
      this.autoStart = true;
    },
    // 取消订单逻辑
    cancerOrder () {
      if (this.$route.query.type == "buy") {
        this.cancerReasonVisible = true;
      } else {
        this.cancerVisible = true;
      }
    },
    // 转赠订单取消（无原因）
    donationCancerOrder () {
      if (this.$route.query.type == "composite") {
        api
          .post(`mall/mergetoken/order/cancel/${this.$route.query.orderId}`)
          .then((result) => {
            if (result.data.success) {
              this.$toast.success("成功");
              this.cancerVisible = false;
              this.cancerReasonVisible = false;
              this.activeIndex = "5";
              localStorage.setItem("orderIndex",5);
              this.$router.push(
                "/orderDetail?orderId=" +
                this.$route.query.orderId +
                "&type=" +
                this.$route.query.type
              );
              window.location.reload();
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require("../assets/icon/toast-error.png"),
              });
            }
          });
      } else {
        api.post("gift/cancel/" + this.$route.query.orderId).then((result) => {
          if (result.data.success) {
            this.$toast.success("成功");
            this.cancerVisible = false;
            this.cancerReasonVisible = false;
            this.activeIndex = "5";
            localStorage.setItem("orderIndex",5);
            this.$router.push(
              "/orderDetail?orderId=" +
              this.$route.query.orderId +
              "&type=" +
              this.$route.query.type
            );
            window.location.reload();
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require("../assets/icon/toast-error.png"),
            });
          }
        });
      }
    },
    // 购买订单取消（有原因）
    cancerOrderReason () {
      if (this.cancerReason == "其他" && this.cancerReasonText.length > 50) {
        this.$toast.fail("取消原因不能超过50个字！");
      } else {
        api
          .post("order/cancel",{
            id: this.orderInfo.id,
            text:
              this.cancerReason == "其他"
                ? this.cancerReasonText
                : this.cancerReason,
          })
          .then((result) => {
            if (result.data.success) {
              this.cancerReasonVisible = false;
              this.$toast.success("成功");
              localStorage.setItem("orderIndex",4);
              window.location.reload();
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require("../assets/icon/toast-error.png"),
              });
            }
          });
      }
    },
    // 获取订单详情信息
    getOrderDetailsInfo () {
      var url =
        this.$route.query.type == "buy"
          ? "order/detail/" + this.$route.query.unique
          : "gift/detail/" + this.$route.query.orderId;
      // 购买订单详情
      if (this.$route.query.type == "buy") {
        api.get(url).then((result) => {
          if (result.data.success) {
            this.orderInfo = result.data.data;
            this.payType = this.orderInfo.payType;
            this.waitForPay =
              this.orderInfo.status == 0 &&
              this.orderInfo.refundStatus == 0 &&
              this.orderInfo.paid == 0; //待支付
            this.finishStatus =
              this.orderInfo.status == 0 &&
              this.orderInfo.refundStatus == 0 &&
              this.orderInfo.paid == 1; // 已完成状态
            this.cancerStatus =
              this.orderInfo.status == 4 &&
              this.orderInfo.refundStatus == 0 &&
              this.orderInfo.paid == 0; //取消状态
            if (this.waitForPay) {
              this.showtime();
            }
            localStorage.setItem("orderIndex",this.waitForPay ? '2' : this.finishStatus ? '3' : this.cancerStatus ? '4' : '1');
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require("../assets/icon/toast-error.png"),
            });
          }
          this.$store.commit("HIDE_APPLOADING");
        });

      } else if (this.$route.query.type == "composite") {
        api
          .get(`mall/mergetoken/order/${this.$route.query.orderId}`)
          .then((result) => {
            if (result.data.success) {
              this.orderInfo = result.data.data;
              this.materialListCache =
                result.data.data.mergeTokenOrder.destroyAssetList;
              if (this.materialListCache.length > 0) {
                this.materialListShow = this.materialListCache.slice(0,1);
              }
              this.payType = this.orderInfo.mergeTokenOrder.payType;
              let status = this.orderInfo.mergeTokenOrder.status;
              status == "PAYING"
                ? (this.waitForPay = true)
                : status == "FINISHED"
                  ? (this.finishStatus = true)
                  : status == "CANCELED"
                    ? (this.cancerStatus = true)
                    : status == "ON_CHAIN"
                      ? (this.onChainStatus = true)
                      : "";
              localStorage.setItem("orderIndex",status == "PAYING" ? '2' : status == "FINISHED" ? '4' : status == "CANCELED" ? '5' : status == "ON_CHAIN" ? '3' : '1');
              if (this.waitForPay) {
                this.showtime();
              }
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require("../assets/icon/toast-error.png"),
              });
            }
            this.$store.commit("HIDE_APPLOADING");
          });
      } else {
        // 转赠订单详情
        api
          .post(url)
          .then(result => {
            if (result.data.success) {
              this.orderInfo = result.data.data
              this.donationCollectionList = []
              this.donationCollectionShowList = []
              this.donationCollectionList = result.data.data.giftOrderItemList
              this.donationCollectionShowList.push(this.donationCollectionList[0])
              if (this.donationCollectionList.length > 1) {
                this.donationCollectionListshow = true
              }
              this.payType = this.orderInfo.payType
              this.waitForPay = this.orderInfo.status == 5  //待支付
              this.onChainStatus = this.orderInfo.status == 6  //上链中
              this.finishStatus = this.orderInfo.status == 7  // 已完成状态
              this.cancerStatus = this.orderInfo.status == 8  //取消状态
              this.donationStatus = this.$route.query.donationStatus == 1 //转赠状态
              if (this.waitForPay) {
                this.showtime()
              }
              localStorage.setItem("orderIndex",this.waitForPay ? '2' : this.finishStatus ? '4' : this.cancerStatus ? '5' : this.onChainStatus ? '3' : '1');
            } else {
              this.$toast({
                message: result.data.msg,
                icon: require('../assets/icon/toast-error.png'),
              });
            }
            this.$store.commit("HIDE_APPLOADING");
          });
      }
    },
    // 购买订单超时逻辑
    timeFinish () {
      this.$store.commit("SHOW_APPLOADING");
      setTimeout(() => {
        this.orderCancer();
      },900000);
      this.getOrderDetailsInfo();
    },
    // 购买订单超时自动取消
    orderCancer () {
      var url =
        this.$route.query.type == "buy"
          ? "order/detail/" + this.$route.query.unique
          : "gift/detail/" + this.$route.query.orderId;
      api.get(url).then((result) => {
        if (result.data.success) {
          if (this.$route.query.type == "buy") {
            if (
              result.data.data.status == 0 &&
              result.data.data.refundStatus == 0 &&
              result.data.data.paid == 0
            ) {
              api
                .post("order/cancel",{
                  id: this.orderInfo.id,
                  text: "订单已超时",
                })
                .then((result) => {
                  if (result.data.success) {
                    this.$toast.success("成功");
                    this.cancerReasonVisible = false;
                    this.activeIndex = "4";
                    localStorage.setItem("orderIndex",4);
                    this.$router.push(
                      "/orderDetail?unique=" +
                      this.$route.query.unique +
                      "&type=" +
                      this.$route.query.type
                    );
                    window.location.reload();
                  } else {
                    this.$toast({
                      message: result.data.msg,
                      icon: require("../assets/icon/toast-error.png"),
                    });
                  }
                });
            }
          } else {
            // 合成超时处理
            window.location.reload();
          }

        } else {
          this.$toast({
            message: result.data.msg,
            icon: require("../assets/icon/toast-error.png"),
          });
        }
      });
    },
    // 复制订单号
    copy () {
      var clipboard = new Clipboard(".orderNo-copy");
      clipboard.on("success",(e) => {
        this.$toast.success("复制成功");
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error",(e) => {
        // 不支持复制
        this.$toast.fail("复制失败");
        // 释放内存
        clipboard.destroy();
      });
    },
    // 点击购买逻辑
    payNow () {
      // 微信中打开为支付宝支付
      if (this.isWechat() && this.payType == "alipay") {
        this.browerOpenVisible = true;
        // 微信中微信支付
      } else if (this.isWechat() && this.payType == "weixin") {
        this.getAppId();
      } else {
        // 浏览器支付
        this.pay();
      }
    },
    // 微信内支付获取微信appId
    getAppId () {
      localStorage.setItem("orderPayType",this.$route.query.type);
      if (this.isWechat()) {
        localStorage.setItem(
          "compositePayInfo",
          JSON.stringify({
            from:
              this.payType == "alipay"
                ? "h5"
                : this.isWechat()
                  ? "wechat"
                  : "weixinh5", //wechat,weixinh5
            payType: this.payType,
            orderId: Number(this.$route.query.orderId),
          })
        );
        window.location.href = getAuthCode(window.location.hash);
      } else {
        this.pay();
      }
    },
    // 支付处理逻辑
    pay () {
      let payFrom = this.payType == 'alipay' ? 'alih5' : this.isWechat() ? 'wechat' : 'weixinh5'
      var url = this.$route.query.type == 'buy' ? 'order/pay' : this.$route.query.type == 'composite' ? `mall/mergetoken/order/payOrder` : 'gift/pay',
        param = this.$route.query.type == 'buy' ? { from: payFrom,payType: this.payType,uni: this.$route.query.unique } :
          this.$route.query.type == 'composite' ? {
            // configId: this.orderInfo.mergeTokenOrder.configId,
            // destroyAssets: this.orderInfo.mergeTokenOrder.destroyAssetList.map(i => i.uniqueCode),
            // mergeNum: this.orderInfo.mergeTokenOrder.mergeNum,
            from: this.payType == 'alipay' ? 'h5' : this.isWechat() ? 'wechat' : 'weixinh5', //wechat,weixinh5
            // creator: {},
            payType: this.orderInfo.mergeTokenOrder.payType,
            orderId: Number(this.$route.query.orderId),
          } :
            { from: this.payType == 'alipay' ? 'h5' : this.isWechat() ? 'wechat' : 'weixinh5',payType: this.payType,uni: this.$route.query.orderId }

      api
        .post(url,param)
        .then(result => {
          if (result.data.success) {
            if (Cookies.get('Device') == 'pico') {
              if (this.$route.query.type == 'buy') {
                this.$router.push('/share?unique=' + this.orderInfo.unique + '&type=' + this.$route.query.type)
              } else {
                this.$router.push('/share?unique=' + this.orderInfo.orderId + '&type=' + this.$route.query.type)
              }

            } else {
              if (this.payType == 'alipay') {
                const divForm = document.getElementsByTagName('divform')
                if (divForm.length) {
                  document.body.removeChild(divForm[0])
                }
                const div = document.createElement('divform');
                div.innerHTML = result.data.data.result.jsConfig.mweb_url; // data就是接口返回的form 表单字符串
                document.body.appendChild(div);
                // document.forms[0].setAttribute('target','_blank') // 新开窗口跳转
                document.forms[0].submit();
              } else {
                if (this.isWechat()) {
                  if (localStorage.getItem('orderPayType') == 'buy') {
                    window.location.href = config.locationHref + 'paySuccess?unique=' + localStorage.getItem('orderWeixinPayUnique') + '&type=buy'
                  } else if (localStorage.getItem('orderPayType') == 'composite') {
                    window.location.href = config.locationHref + 'paySuccess?unique=' + localStorage.getItem('orderWeixinPayOrderId') + '&type=composite'
                  } else {
                    window.location.href = config.locationHref + 'paySuccess?unique=' + localStorage.getItem('orderWeixinPayOrderId') + '&type=donation'
                  }

                  function onBridgeReady () {
                    WeixinJSBridge.invoke(
                      'getBrandWCPayRequest',{
                      "appId": result.data.data.result.jsConfig.appId,     //公众号ID，由商户传入     
                      "timeStamp": result.data.data.result.jsConfig.timestamp,         //时间戳，自1970年以来的秒数     
                      "nonceStr": result.data.data.result.jsConfig.nonceStr, //随机串     
                      "package": result.data.data.result.jsConfig.package,
                      "signType": result.data.data.result.jsConfig.signType,         //微信签名方式：     
                      "paySign": result.data.data.result.jsConfig.paySign //微信签名 
                    },
                      function (res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                          // 使用以上方式判断前端返回,微信团队郑重提示：
                          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                          // if (localStorage.getItem('orderPayType') == 'buy') {
                          //   window.location.href = config.locationHref + 'paySuccess?unique=' + localStorage.getItem('orderWeixinPayUnique') + '&type=buy'
                          // } else {
                          //   window.location.href = config.locationHref + 'paySuccess?unique=' + localStorage.getItem('orderWeixinPayOrderId') + '&type=donation'
                          // }
                        }
                      });
                  }
                  if (typeof WeixinJSBridge == "undefined") {
                    if (document.addEventListener) {
                      document.addEventListener('WeixinJSBridgeReady',onBridgeReady,false);
                    } else if (document.attachEvent) {
                      document.attachEvent('WeixinJSBridgeReady',onBridgeReady);
                      document.attachEvent('onWeixinJSBridgeReady',onBridgeReady);
                    }
                  } else {
                    onBridgeReady();
                  }
                } else {
                  setTimeout(() => {
                    window.location.href = result.data.data.result.jsConfig.mweb_url
                  },300);
                  if (this.$route.query.type == 'buy') {
                    window.location.href = config.locationHref + 'paySuccess?unique=' + this.$route.query.unique + '&type=buy'
                  } else if (this.$route.query.type == 'composite') {
                    window.location.href = config.locationHref + 'paySuccess?unique=' + this.$route.query.orderId + '&type=composite'
                  } else {
                    window.location.href = config.locationHref + 'paySuccess?unique=' + this.$route.query.orderId + '&type=donation'
                  }
                }
              }
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })

    },
    donationShowMore () {
      this.donationCollectionShowList = this.donationCollectionList
      this.donationCollectionListshow = false
    },
    showMore () {
      this.materialHide = true;
      this.materialListShow = this.materialListCache;
    },
  },
};
</script>
<style lang="less" scoped>
.order-details-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .order-detail-img-name-price-img {
    display: flex;
    justify-content: center;
    align-items: center;
    &-inline {
      display: block;
      text-align: center;
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
  .buy-now {
    width: 90%;
    height: 5rem;
    background: #ffffff;
    border-radius: 1rem;
    z-index: 1000;
    padding: 1.5625rem 0 1.75rem 0;

    .order-detail-prise-buy {
      width: 100%;
    }
  }

  .mask {
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
    height: 100%;
    filter: alpha(opacity=60);
    /*设置透明度为60%*/
    opacity: 0.6;
    /*非IE浏览器下设置透明度为60%*/
    z-index: 999;
  }
  .cancer-order-title {
    font-size: 20px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #d8d8d8;
    line-height: 20px;
    margin-top: 1.75rem;
  }
  .cancer-submit {
    width: 90%;
    height: 2.875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }

  /deep/.el-radio > .el-radio__input > .el-radio__inner {
    border-radius: 100%;
    width: 1.125rem;
    height: 1.125rem;
    background: #545454 !important;
    border: none;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner::after {
    width: 8px;
    height: 8px;
    background: #f3cdb6;
  }

  /deep/.el-radio__label {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #d8d8d8;
    line-height: 20px;
  }

  /deep/.el-textarea__inner {
    margin-top: 16px;
    width: 90%;
    height: 96px;
    background: #4a4a4a;
    border-radius: 4px;
    border: none;
    outline: none;
  }
  .van-button {
    width: 90%;
    height: 2.875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .composite {
    width: 94%;
    margin: 0.625rem 3%;
    background: #262728;
    border-radius: 8px;
    padding: 13px 0;
    .title {
      font-size: 16px;
      color: #ffffff;
      line-height: 22px;
      margin: 0 0 8px 21px;
    }
    .list {
      margin-left: 21px;
      .item {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-left: 20px;
        border-top: 1px solid #343434;
        padding: 21px 0;
        .icon {
          width: 80px;
          height: 80px;
          border-radius: 0.5rem;
          overflow: hidden;
          background: #ffffff;
          &-container {
            width: 80px;
            height: 80px;
            display: block;
            position: relative;
            img {
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate3d(-50%, -50%, 0);
            }
          }
        }
        .info {
          margin-left: 15px;
          height: 80px;
          .name {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            margin: 15px 0 9px;
          }
          .id {
            font-size: 12px;
            font-weight: normal;
            color: #999999;
          }
        }
      }
    }
    .expand {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 12px;
        color: #666666;
      }
      img {
        width: 14px;
        height: 9px;
        margin-left: 6px;
      }
    }
  }
}
</style>